<template>
  <div>
    <el-card>
      <el-button
        type="primary"
        size="small"
        class="btnAdd"
        @click="dialogVisible = true"
        >新增分类
      </el-button>
    </el-card>

    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <!-- <el-table-column type="selection" width="55"></el-table-column> -->
        <el-table-column prop="id" width="100" label="编号" />
        <el-table-column prop="name" label="分类" width="180" />
        <el-table-column prop="hide" label="状态">
          <template slot-scope="ew">
            <span v-if="ew.row.hide == 0">显示</span>
            <span v-else>隐藏</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderNum" label="排序" sortable="custom">
          <template #header>
            <span>排序</span>
            <span style="font-size:12px"
              ><br />（当前最大值：{{ orderMax }}）</span
            >
            <el-tooltip effect="dark" placement="top">
              <div slot="content">数值越大，显示越靠前</div>
              <i class="el-icon-warning-outline" style="margin-left: 5px;" />
            </el-tooltip>
          </template>
          <template slot-scope="scope">
            <div class="input-box">
              <el-input
                v-if="pai[scope.$index].pai"
                v-model="scope.row.orderNum"
                size="small"
                class="id"
                @blur="
                  handleInputBlur(
                    scope.$index,
                    scope.row.id,
                    scope.row.orderNum,
                    scope.row.name
                  )
                "
              />
            </div>
            <span
              v-if="!pai[scope.$index].pai"
              class="el-icon-edit"
              @click="showinput(scope.$index)"
            >
              {{ scope.row.orderNum }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              class="skyblue"
              style="color:skyblue;"
              @click="getform(scope.row.id)"
              >编辑</el-button
            >
            <el-button

              type="text"
              icon="el-icon-delete"
              size="mini"
              style="color:red"
              @click="del(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :page-size="pagesize"
        background
        layout="total, prev, pager, next"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <el-dialog title="新增分类" :visible.sync="dialogVisible" width="50%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="分类名称">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="form.orderNum" />
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.hide" placeholder="请选择">
            <el-option label="显示" :value="0" />
            <el-option label="隐藏" :value="1" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="add">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="修改分类" :visible.sync="dialogVisiblex" width="50%">
      <el-form ref="editform" :model="editform" label-width="80px">
        <el-form-item label="分类名称">
          <el-input v-model="editform.name" />
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="editform.orderNum" />
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="editform.hide" placeholder="请选择">
            <el-option label="显示" :value="0" />
            <el-option label="隐藏" :value="1" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblex = false">取 消</el-button>
        <el-button type="primary" @click="addedit">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return {
      btn: true,
      list: [],
      pai: [],
      paiform: {},
      dialogVisible: false,
      form: {
        name: '',
        orderNum: '',
        hide: ''
      },
      order: '',
      editform: {},
      pagesize: 10,
      currentPage: 1,
      total: 0,
      dialogVisiblex: false,
      orderMax: 0
    }
  },
  created() {
    this.getlsit()
  },
  watch: {
    dialogVisible(newValue, oldValue) {
      if (!newValue) {
        this.form = this.$options.data.call(this).form
      }
    }
  },
  methods: {
    sortChange(el) {
      let str = ''
      if (el.prop == 'orderNum') {
        str = 'ac_order_num'
      }
      str += ' ' + el.order.slice(0, el.order.length - 6)
      this.order = str
      this.getlsit()
    },
    showinput(id) {
      console.log(id)
      this.pai[id].pai = true
      setTimeout(() => {
        $('.id input').focus()
      }, 20)
    },
    handleInputBlur(id, ids, orderNum, name) {
      this.paiform.id = ids
      this.paiform.orderNum = orderNum
      this.paiform.name = name
      this.$http
        .post('/admin/ActivityCategory/edit', this.paiform)
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlsit()
          }
        })
      this.pai[id].pai = false
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    addedit() {
      if (this.btn) {
        this.$http
          .post('/admin/ActivityCategory/edit', this.editform)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.dialogVisiblex = false
              this.getlsit()
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    getform(id) {
      this.dialogVisiblex = true
      this.$http
        .get('/admin/ActivityCategory/getById?id=' + id)
        .then(({ data: res }) => {
          //   console.log(res)
          if (res.errorCode == 200) {
            // this.$message.success(res.message)
            this.editform = res.data
          }
        })
    },
    getlsit() {
      this.$http
        .get('/admin/ActivityCategory/getList', {
          params: {
            page: this.currentPage,
            pageSize: this.pagesize,
            order: this.order
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            // console.log(res.data.list)
            this.list = res.data.list
            this.total = res.data.totalCount
            this.orderMax = res.data.orderMax
            this.list.forEach(item => {
              this.pai.push({
                id: item.id,
                pai: false
              })
            })
          }
        })
    },
    add() {
      if (this.btn) {
        this.$http.post('/admin/ActivityCategory/add', this.form).then(res => {
          console.log(res)
          if (res.data.errorCode == 200) {
            this.$message.success(res.data.message)
            this.dialogVisible = false
            // location.reload()
            this.getlsit()
          } else {
            this.$message.error(res.data.message)
          }
          this.btn = true
        })
      }
      this.btn = false
    },
    del(ids) {
      this.$confirm('是否删除该分类？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/ActivityCategory/del', {
              params: {
                id: ids
              }
            })
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success(res.message)
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                this.getlsit()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    handleSizeChange(e) {
      // this.pagesize=e
      console.log(e)
      this.getlsit()
    },
    handleCurrentChange(e) {
      this.currentPage = e
      // console.log(e);
      this.getlsit()
    }
  }
}
</script>

<style lang="less">
.red {
  color: red;
}

.skyblue {
  color: skyblue;
}

.green {
  color: green;
}

.yellow {
  color: pink;
}
</style>
